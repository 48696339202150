@import "~utils/vars";

.wrapper {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  background-color: #253c86;
  color: white;

  .links {
    display: flex;
    // justify-content: space-evenly;
    align-items: start;

    .linksCol {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      .title {
        margin-bottom: 0.5em;
        font-weight: 600;
      }

      nav {
        display: flex;
        flex-direction: column;
        align-items: start;

        span {
          margin-bottom: 0.1em;
        }
      }
    }
  }

  // .copyright {
  //   color: #a5a5a5;
  //   display: flex;
  //   justify-content: flex-end;
  // }
}

@media (min-width: 350px) and (max-width: 900px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: #253c86;
    color: white;
    text-align: center;
    .links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .linksCol {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title {
          margin-bottom: 0.5em;
          font-weight: 600;
        }

        nav {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin-bottom: 0.1em;
          }
        }
      }
    }
  }
}
